@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0;
  background-color: #0096d6;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}

.mobile-body {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: "white";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body>div.mobile-root {
  background-color: white;

  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.safe-area-top-bar {
  height: constant(safe-area-inset-top);
  height: env(safe-area-inset-top);
  background-color: #0096d6;
}

.safe-area-bottom-bar {
  height: constant(safe-area-inset-bottom);
  height: env(safe-area-inset-bottom);
  background-color: #0096d6;
}

header.MuiAppBar-positionFixed {
  top: auto;
}

.app-bottom-bar {
  bottom: constant(safe-area-inset-bottom) !important;
  bottom: env(safe-area-inset-bottom) !important;
}

.achievements {
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 65px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 65px);
}

.order-details {
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 130px) !important;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 130px) !important;
}

.account-settings {
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 60px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 60px);
}

.cart-summary {
  overflow: auto;
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 130px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 130px);
  padding: 5px;
}

.categories-list,
.goods-list {
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 170px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 170px);
  overflow: auto;
}

.categories-list-item {
  height: calc((100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 170px) / 3);
  height: calc((100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 170px) / 3);
}

.service-group-image {
  height: calc((100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom))/3);
  height: calc((100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))/3);
}

.services-list {
  overflow: auto;
  height: calc((100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom)) * 2/3 - 65px);
  height: calc((100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) * 2/3 - 65px);
}

.all-services-list {
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 265px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 265px);
}

.shopping-cart {
  overflow: auto;
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 120px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 120px);
}

.shopping-cart-services-empty-list {
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 160px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 160px);
}

.address-book {
  overflow: auto;
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 120px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 120px);
}

.address-book-my-addresses-list {
  overflow: auto;
  max-height: calc((100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 120px) / 2);
  max-height: calc((100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 120px) / 2);
}

.orders-history-list {
  overflow: auto;
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 180px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 180px);
}

.register-list {
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 105px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 105px);
}

.sales-points-list {
  overflow: auto;
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 245px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 245px);
}

.where-to-list,
.account-items-list,
.info-items-list,
.credits-box {
  padding: 5px 20px 0;
  overflow: auto;
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 80px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 80px);
}

.payment-methods-list,
.support-container {
  overflow: auto;
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 80px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 80px);
}

.goods-item {
  height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 80px);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 80px);
  overflow: auto;
}

/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

.loginBtn:disabled {
  background-color: gray !important;
  background-image: none !important;
}


/* Facebook */
.loginBtn--facebook {
  width: 220px;
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354C8C;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('logo/icon_facebook.png') 6px 6px no-repeat;
}

.loginBtn--facebook:disabled::before {
  border-right: darkgray 1px solid !important;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}


/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39;
  width: 220px;
}

.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('logo/icon_google.png') 6px 6px no-repeat;
}

.loginBtn--google:disabled::before {
  border-right: darkgray 1px solid !important;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}